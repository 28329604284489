import React, { Component } from 'react'
import './Button.css'
export class Button extends Component {



    render() {
        return (
            <div>
                <div className="buttonContainer">
  <a href="https://bsszczytno.pl/bankowosc-elektroniczna/" target="_blank" className="button" >Więcej</a>
</div>
            </div>
        )
    }
}

export default Button
