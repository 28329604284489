
import React from 'react'
import logo from './resources/Logo_iOS.png'
import './nav.css'
function Nav() {
    return (
        <div className="Navigation">
            <div className="Nav__logo"><img src={logo} /></div>
            <div className="Nav__items">
                <ul>
                    
                </ul>
            </div>
        </div>
    )
}

export default Nav
